import React from 'react'
import Img from "gatsby-image"
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import ThemeContext from "../../context/ThemeContext"

export default class Card extends React.Component {
  render() {
    const { story, color, overlay, menuCard, locationId } = this.props
    const { frontmatter } = story
    const { description, title, featuredimage } = frontmatter
    const truncate = (string, n) => string.substr( 0 , n - 1 ) + ( string.length > n ? '...' : '' );
    return (
      <ThemeContext.Consumer>
        {theme => (
          <AniLink duration={0.25} fade to={story.fields.slug} className="cStoryCard" state={{ color: color, overlay: overlay, locationId: locationId }} onClick={() => theme.updatePrevPath(window.location.pathname)} onFocus={this.props.onFocus || null}>
            {featuredimage && featuredimage.childImageSharp && featuredimage.childImageSharp.fluid ? 
              <Img
                fluid={featuredimage.childImageSharp.fluid}
                alt={title}
                className="cStoryCard-image"
                />
            : null}
            <div className={`cStoryCard-content ${menuCard ? 'cStoryCard-menu-content' : ''}`}>
              <p className="cStoryCard-title">{ title }</p>
              {!menuCard ? (
                <div className="cStoryCard-excerpt">
                  <p>{ truncate(description, 125) }</p>
                  <span className="cStoryCard-readMore link">Read More</span>
                </div>
              ) : null}
            </div>
          </AniLink>
        )}
      </ThemeContext.Consumer>
    )
  }
}
