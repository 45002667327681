import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import ThemeContext from "../context/ThemeContext"
import StoryCard from './Story/Card'

class PeopleAndPlaces extends React.Component {
  componentDidMount() {
    this.context.hideNav()
  }
  render() {
    const { data, prevPage } = this.props
    const featuredLocations = data && data.markdownRemark && data.markdownRemark.fields && data.markdownRemark.fields.featuredLocations
    return (
      <ThemeContext.Consumer>
        {theme => (
          <div className="cPeopleAndPlaces cSplitScreen">
            <div className="cPeopleAndPlaces-left cSplitScreen-content">
              <div className="cSplitScreen-heading">
                <div className="cSplitScreen-icon">
                  <img src="/img/stack-of-books.png" alt="stack of books illustration" />
                </div>
                <p className="cSplitScreen-title">Cleo's Books</p>
                <AniLink fade to={prevPage ? prevPage : '/'} duration={0.25}  className="close-icon" aria-label="Close"></AniLink>
              </div>
              <div className="cSplitScreen-path">
                <div className="cSplitScreen-icon">
                  <img src="/img/stack-of-books.png" alt="stack of books illustration" />
                </div>
                <p className="cSplitScreen-path-text"><AniLink fade to="/menu">Menu</AniLink> / People &amp; Places</p>
                <AniLink fade to={prevPage ? prevPage : '/'} duration={0.25}  className="close-icon" aria-label="Close"></AniLink>
              </div>
              <div className="cPeopleAndPlaces-inner">
              {featuredLocations &&
                featuredLocations.map(({ location: post }, idx) => (
                  <div className="cPeopleAndPlaces-block" key={`${post.id}-${idx}`}>
                    <div className="cPeopleAndPlaces-block-heading">
                      <h2 className="cPeopleAndPlaces-block-title">
                      <Link to={post.fields.slug} className="">
                        {post.frontmatter.title}
                      </Link></h2>
                      {/* <Link to={post.fields.slug} className="cPeopleAndPlaces-block-link link">
                        View on map
                      </Link> */}
                    </div>
                    <div className="cPeopleAndPlaces-block-content">
                      {post.fields.stories ? post.fields.stories.map((story, idx) => (
                        <div className="cPeopleAndPlaces-block-item" key={idx}>
                          <StoryCard story={story} menuCard />
                        </div>
                      )) : null}
                    </div>
                  </div>
                ))
              }
              </div>
            </div>
            <AniLink className="cPeopleAndPlaces-right cSplitScreen-cover" fade to={prevPage ? prevPage : '/'} duration={0.25} >
              <p className="cSplitScreen-cover-title">Cleo's Books</p>
            </AniLink>
          </div>
        )}
      </ThemeContext.Consumer>
    )
  }
}

PeopleAndPlaces.contextType = ThemeContext

PeopleAndPlaces.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      fields: PropTypes.shape({
        featuredLocations: PropTypes.arrayOf(
          PropTypes.shape({
            location: PropTypes.shape({
              fields: PropTypes.shape({
                slug: PropTypes.string,
                stories: PropTypes.arrayOf(
                  PropTypes.shape({
                    fields: PropTypes.shape({
                      slug: PropTypes.string,
                    }),
                    frontmatter: PropTypes.shape({
                      description: PropTypes.string,
                      featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
                      title: PropTypes.string
                    })
                  })
                )
              }),
              frontmatter: PropTypes.shape({
                title: PropTypes.string
              })
            })
          })
        )
      })
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query PeopleAndPlaces {
        markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
          id
          fields {
            featuredLocations {
              location {
                fields {
                  slug
                  stories {
                    frontmatter {
                      description
                      featuredimage {
                        childImageSharp {
                          fluid(maxWidth: 1920, quality: 75) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                      title
                    }
                    fields {
                      slug
                    }
                  }
                }
                frontmatter {
                  title
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <PeopleAndPlaces data={data} />}
  />
)
