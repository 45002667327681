import React from 'react'

import Layout from '../../components/Layout'
import PeopleAndPlaces from '../../components/PeopleAndPlaces'

export default class StoryIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section">
          <PeopleAndPlaces />
        </section>
      </Layout>
    )
  }
}
